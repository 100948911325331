<script lang="ts" setup>
  import { useRoute } from "vue-router"
  import { ref } from "vue"

  import { gettext } from "@/utils/Translation"
  import { getChildById } from "@/services/Child"
  import { updateChildsAssessmentsFromServer } from "@/services/Child"
  import { getLastSavedGrowthAssessmentForChild } from "@/services/GrowthAssessment"
  import {
    getLastSavedFeedingScreeningForChild,
    getLastSavedFeedingObservationForChild,
    getLastChildBestPracticeAssessmentForChild,
  } from "@/services/Feeding"
  import { getLastSavedAnemiaAssessmentForChild } from "@/services/AnemiaAssessment"
  import { getLastDevelopmentalScreeningForChild } from "@/services/DevelopmentalScreening"
  import { DevelopmentalScreening } from "@/models/DevelopmentalScreening"
  import {
    setCurrentViewContext,
    getLastUpdateInfo,
    areCachedDataStale,
    showFlashMessage,
    setStalenessNotificationTime,
  } from "@/utils/GlobalState"
  import { Child } from "@/models/Child"
  import { FeedingScreening, FeedingObservation } from "@/models/Feeding"
  import { getSiteByCmiId } from "@/services/Site"
  import useServerRefresher from "@/composables/useServerRefresher"

  import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
  import ChildHeader from "@/components/children/ChildHeader.vue"
  import LastSynced from "@/components/LastSynced.vue"
  import RecommendationsReferrals from "@/components/assessments/RecommendationsReferrals.vue"
  import AssessmentNote from "@/components/assessments/AssessmentNote.vue"
  import PageLoading from "@/components/PageLoading.vue"
  import StoplightContainer from "@/components/assessments/StoplightContainer.vue"
  import StoplightItem from "@/components/assessments/StoplightItem.vue"

  const orderedModuleNames = ["growth", "bestPractices", "feeding", "anemia", "developmentalScreening"]
  const route = useRoute()
  const childId = parseInt(route.params.childId as string)
  const { $gettext } = gettext
  const { refreshUnderway, serverRefresher } = useServerRefresher()
  const staleNotificationQueryKey = `childAssessments-${childId}`

  // Refs
  const pageReady = ref(false)
  const child = ref(null)
  const site = ref(null)
  const growthAssessment = ref(null)
  const feedingScreening = ref(null)
  const feedingObservation = ref(null)
  const feedingObject = ref(null) // Either a screening or observation object–whichever is more recent.
  const anemiaAssessment = ref(null)
  const developmentalScreening = ref(null)
  const bestPracticesAssessment = ref(null)
  const referrals = ref([])
  const reportCard = ref(null)
  const assessmentNotes = ref(null)
  const expandedAssessmentNotes = ref({}) // Which assessment notes are expanded? (initially: none)
  const nextAssessmentDates = ref(null)
  const lastSynced = ref(undefined)
  let moduleCount: number

  function setReferrals() {
    const results = []
    if (growthAssessment.value?.referrals) {
      results.push(...growthAssessment.value.referrals)
    }
    if (anemiaAssessment.value?.referrals) {
      results.push(...anemiaAssessment.value.referrals)
    }
    // Feeding Observations don't generate referrals
    if (feedingScreening.value?.referrals) {
      results.push(...feedingScreening.value.referrals)
    }
    const devScreeningReferrals = developmentalScreening.value?.getReferrals()
    if (devScreeningReferrals?.length) {
      results.push(...devScreeningReferrals)
    }
    referrals.value = results
  }

  // Return an object representing the growth module's report card contribution
  function getGrowthReportCard() {
    if (growthAssessment.value) {
      return {
        id: "growth",
        label: $gettext("Growth"),
        urlParams: { name: "GrowthRecommendations", params: { assessmentId: growthAssessment.value.id, childId } },
        ...growthAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "growth",
        label: $gettext("Growth"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }

  // Return an object representing the feeding module's report card contribution
  function getFeedingReportCard() {
    // A little different from the others: need to figure out whether obs or screening will represent.
    if (!feedingObject.value) {
      return {
        id: "feeding",
        label: $gettext("Feeding"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
    const urlName = feedingObject.value instanceof FeedingScreening ? "FeedingScreeningRecommendations" : "FeedingObservationRecommendations"
    return {
      id: "feeding",
      label: $gettext("Feeding"),
      urlParams: { name: urlName, params: { assessmentId: feedingObject.value.id, childId } },
      ...feedingObject.value.reportCard
    }
  }

  // Return an object representing the anemia module's report card contribution
  function getAnemiaReportCard() {
    if (anemiaAssessment.value) {
      return {
        id: "anemia",
        label: $gettext("Anemia"),
        urlParams: { name: "AnemiaRecommendations", params: { assessmentId: anemiaAssessment.value.id, childId } },
        ...anemiaAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "anemia",
        label: $gettext("Anemia"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }

  // Return an object representing the developmental screening module's report card contribution
  function getDevelopmentalScreeningReportCard() {
    let stoplightColor = "incomplete"
    let statements = [$gettext("Not yet assessed")]
    let urlParams = null

    if (developmentalScreening.value) {
      urlParams = { name: "DevelopmentalScreeningRecommendations", params: { assessmentId: developmentalScreening.value.id, childId } }
      stoplightColor = developmentalScreening.value.reportCard.stoplightColor
      statements = developmentalScreening.value.reportCard.statements
    }
    else if (DevelopmentalScreening.isChildTooYoung(child.value)) {
      statements = [$gettext("Too young")]
    }
    else if (DevelopmentalScreening.isChildTooOld(child.value)) {
      statements = [$gettext("Aged out")]
    }
    return {
      id: "developmentalScreening",
      label: $gettext("Developmental Screening"),
      stoplightColor,
      statements,
      urlParams,
    }
  }

  function getBestPracticesReportCard() {
    if (bestPracticesAssessment.value) {
      return {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        urlParams: { name: "PracticesRecommendations", params: { assessmentId: bestPracticesAssessment.value.id, childId } },
        ...bestPracticesAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }


  function setNextAssessmentDates() {
    const results = []
    if (site.value.growthEnabled) {
      results.push({
        id: "growth",
        label: $gettext("Growth"),
        text: child.value.nextGrowthAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'g' } },
      })
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      results.push({
        id: "bestPractices",
        label: $gettext("Best Practices"),
        text: child.value.nextBestPracticesAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'b' } },
      })
    }
    if (site.value.isFeedingScreeningEnabled) {
      let label = child.value.nextMealtimeAssessmentType === "observation" ? $gettext("Feeding observation") : $gettext("Feeding screening")
      let text: string
      const assessmentType = child.value.nextMealtimeAssessmentType === "observation" ? "o" : "f"
      const urlParams = { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType } }
      if (child.value.nextMealtimeAssessmentType === "observation" && feedingObject.value instanceof FeedingObservation && feedingObject.value.isEditable) {
        urlParams.name = "FeedingObservationFormMenu"
        urlParams.params = { childId, assessmentId: feedingObject.value.id }
      }
      if (child.value.nextMealtimeAssessmentDate) {
        text = child.value.nextMealtimeAssessmentDate.toLocaleDateString()
      }
      else if (child.value.nextMealtimeAssessmentType === "aged-out") {
        text = $gettext("Aged out")
        label = $gettext("Feeding")
      }
      else {
        text = $gettext("As soon as possible")
      }
      results.push({
        id: "feeding",
        label,
        text,
        urlParams,
      })
    }
    if (site.value.anemiaEnabled) {
      results.push({
        id: "anemia",
        label: $gettext("Anemia"),
        text: child.value.nextAnemiaAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: "a" } },
      })
    }
    if (site.value.earlyidEnabled) {
      let showLink = true
      let text = child.value.nextEarlyidAssessmentDate?.toLocaleDateString()
      if (!text) {
        if (DevelopmentalScreening.isChildTooYoung(child.value)) {
          text = $gettext("Too young")
          showLink = false
        }
        else if (DevelopmentalScreening.isChildTooOld(child.value)) {
          text = $gettext("Aged out")
          showLink = false
        }
        else {
          text = $gettext("As soon as possible")
        }
      }
      results.push({
        id: "earlyid",
        label: $gettext("Developmental Screening"),
        text,
        urlParams: showLink ? { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'd' } } : null,
    })
    }
    nextAssessmentDates.value = results
  }

  // Add each module to the report card as appropriate in the context of this site.
  async function attemptReportCardConstruction() {
    const results = {}
    if (site.value.growthEnabled) {
      results["growth"] = getGrowthReportCard()
    }
    if (site.value.isFeedingScreeningEnabled) {
      results["feeding"] = getFeedingReportCard()
    }
    if (site.value.anemiaEnabled) {
      results["anemia"] = getAnemiaReportCard()
    }
    if (site.value.earlyidEnabled) {
      results["developmentalScreening"] = getDevelopmentalScreeningReportCard()
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      results["bestPractices"] = getBestPracticesReportCard()
    }
    reportCard.value = results
    lastSynced.value = await getLastUpdateInfo({ type: "childAssessments", localItemId: childId })
  }

  function reportCardIterator() {
    const results = []
    for (let i=0; i < orderedModuleNames.length; i++) {
      const name = orderedModuleNames[i]
      if (name in reportCard.value) {
        results.push(reportCard.value[name])
      }
    }
    return results
  }

  function setAssessmentNotes() {
    const getNonEmptyListOrNull = candidate => {
      return candidate && candidate.length ? candidate : null
    }
    const results = {}
    if (site.value.growthEnabled) {
      expandedAssessmentNotes.value["growth"] = false
      results["growth"] = {
        id: "growth",
        label: $gettext("Growth"),
        notes: getNonEmptyListOrNull(growthAssessment.value?.notes),
      }
      if (growthAssessment.value && results["growth"].notes) {
        results["growth"].urlParams = { name: "GrowthRecommendations", params: { assessmentId: growthAssessment.value.id, childId } }
      }
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      expandedAssessmentNotes.value["bestPractices"] = false
      results["bestPractices"] = {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        notes: getNonEmptyListOrNull(bestPracticesAssessment.value?.notes),
      }
      if (bestPracticesAssessment.value && results["bestPractices"].notes) {
        results["bestPractices"].urlParams = { name: "PracticesRecommendations", params: { assessmentId: bestPracticesAssessment.value.id, childId } }
      }
    }
    if (site.value.isFeedingScreeningEnabled) {
      expandedAssessmentNotes.value["feeding"] = false
      results["feeding"] = {
        id: "feeding",
        label: $gettext("Feeding"),
        notes: getNonEmptyListOrNull(feedingObject.value?.notes),
      }
      if (feedingObject.value && results["feeding"].notes) {
        const urlName = feedingObject.value instanceof FeedingScreening ? "FeedingScreeningRecommendations" : "FeedingObservationRecommendations"
        results["feeding"].urlParams = { name: urlName, params: { assessmentId: feedingObject.value.id, childId } }
      }
    }
    if (site.value.anemiaEnabled) {
      expandedAssessmentNotes.value["anemia"] = false
      results["anemia"] = {
        label: $gettext("Anemia"),
        id: "anemia",
        notes: getNonEmptyListOrNull(anemiaAssessment.value?.notes),
      }
      if (anemiaAssessment.value && results["anemia"].notes) {
        results["anemia"].urlParams = { name: "AnemiaRecommendations", params: { assessmentId: anemiaAssessment.value.id, childId } }
      }
    }
    if (site.value.earlyidEnabled) {
      expandedAssessmentNotes.value["developmentalScreening"] = false
      results["developmentalScreening"] = {
        id: "developmentalScreening",
        label: $gettext("Developmental Screening"),
        notes: getNonEmptyListOrNull(developmentalScreening.value?.notes),
      }
      if (developmentalScreening.value && results["developmentalScreening"].notes) {
        results["developmentalScreening"].urlParams = { name: "DevelopmentalScreeningRecommendations", params: { assessmentId: developmentalScreening.value.id, childId } }
      }
    }
    assessmentNotes.value = results
  }

  function assessmentNotesIterator() {
    const results = []
    for (let i=0; i < orderedModuleNames.length; i++) {
      const name = orderedModuleNames[i]
      if (name in assessmentNotes.value) {
        results.push(assessmentNotes.value[name])
      }
    }
    return results
  }

  // Set refs for each of the relevant assessment types with the most recent
  // of each type. Resolves to a boolean indicating if there were any in the first
  // place.
  async function getLocalAssessments() {
    let haveAssessments = false
    if (site.value.growthEnabled) {
      growthAssessment.value = await getLastSavedGrowthAssessmentForChild(child.value)
      if (growthAssessment.value) {
        await growthAssessment.value.processAssessment()
        haveAssessments = true
      }
    }
    if (site.value.isFeedingScreeningEnabled) {
      feedingScreening.value = await getLastSavedFeedingScreeningForChild(child.value)
      if (feedingScreening.value) {
        haveAssessments = true
      }
      if (site.value.isFeedingObservationEnabled) {
        feedingObservation.value = await getLastSavedFeedingObservationForChild(child.value)
        if (feedingObservation.value) {
          await feedingObservation.value.processAssessment()
          haveAssessments = true
        }
      }
      if (feedingScreening.value && feedingObservation.value) {
        feedingObject.value = feedingScreening.value.date_of_assessment > feedingObservation.value.date_of_assessment ? feedingScreening.value : feedingObservation.value
      }
      else {
        feedingObject.value = feedingScreening.value || feedingObservation.value
      }
    }
    if (site.value.anemiaEnabled) {
      anemiaAssessment.value = await getLastSavedAnemiaAssessmentForChild(child.value)
      if (anemiaAssessment.value) {
        await anemiaAssessment.value.processAssessment()
        haveAssessments = true
      }
    }
    if (site.value.earlyidEnabled) {
      developmentalScreening.value = await getLastDevelopmentalScreeningForChild(child.value)
      if (developmentalScreening.value) {
        haveAssessments = true
      }
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      bestPracticesAssessment.value = await getLastChildBestPracticeAssessmentForChild(child.value)
      if (bestPracticesAssessment.value) {
        haveAssessments = true
      }
    }
    return haveAssessments
  }

  async function getAssessmentsFromServer(wasManuallyTriggered = false) {
    const updateAssessments = () => updateChildsAssessmentsFromServer(childId, site.value)
    await serverRefresher(updateAssessments, [childId], staleNotificationQueryKey, wasManuallyTriggered)
      .catch(error => {
        if (error.name === "CONNECTIVITY_REQUIRED") {
          const msg = $gettext("You do not appear to be online.")
          let details = ""
          if (lastSynced.value) {
            details = $gettext("The information shown here was last updated %{ date }.", { date: lastSynced.value })
          }
          else {
            details = $gettext("The information has never been updated from the server.")
          }
          showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
          setStalenessNotificationTime(staleNotificationQueryKey)
        }
        else {
          throw error
        }
      })
  }

  async function hardRefresh() {
    await getAssessmentsFromServer()
    await getLocalAssessments()
    await attemptReportCardConstruction()
    setReferrals()
  }

  async function getData() {
    child.value = new Child(await getChildById(childId))
    setCurrentViewContext(child, "child")
    site.value = await getSiteByCmiId(child.value.siteId)

    moduleCount = [
      site.value.growthEnabled,
      site.value.isFeedingScreeningEnabled,
      site.value.anemiaEnabled,
      site.value.earlyidEnabled,
      site.value.isChildMealtimeBestPracticeAssessmentEnabled,
      ].filter(Boolean).length

    // As long as we have the child and the site, we should have spinners
    // for each of the page components while they flesh themselves out.
    pageReady.value = true

    let serverHasBeenPolled = false

    if (await areCachedDataStale({ type: "childAssessments", localItemId: childId })) {
      await getAssessmentsFromServer()
      serverHasBeenPolled = true
    }

    // Semi-complicated song and dance to avoid hitting the API twice
    // if we don't need to.
    const haveAssessments = await getLocalAssessments()
    // Don't query the server if the child hasn't been uploaded yet!
    if (child.value.cmiId && !haveAssessments && !serverHasBeenPolled) {
      await hardRefresh()
    }
    else {
      await attemptReportCardConstruction()
      setReferrals()
    }
    setNextAssessmentDates()
    setAssessmentNotes()
  }

  getData()
</script>
<template>
  <article
    v-if="pageReady"
    id="child-care-plan"
    class="recommendations"
  >
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <div
      v-if="child && child.createdDuringTraining"
      class="training-mode-label"
    >
      {{ $gettext("Child Created During Training") }}
    </div>
    <PageTitleWithRefresh
      :title="$gettext('Care Plan')"
      :refresh-function="hardRefresh"
      :refresh-underway="refreshUnderway"
    />

    <!-- Referrals -->
    <RecommendationsReferrals :referrals="referrals" />

    <!-- Most Recent Assessments (FKA Report Card) -->
    <StoplightContainer
      :title="$gettext('Most recent assessments')"
      :item-count="moduleCount"
    >
      <template #body>
        <template v-if="reportCard">
          <StoplightItem
            v-for="module in reportCardIterator()"
            :id="`report-card-${module.id}`"
            :key="module.id"
            :title="module.label"
            :link-params="module.urlParams"
            :link-title="module.urlParams ? $gettext('See recommendations') : ''"
            :stoplight-color="module.stoplightColor"
            :item-count="moduleCount"
          >
            <template #body>
              <ul class="list">
                <li
                  v-for="statement in module.statements"
                  :key="statement"
                >
                  {{ statement }}
                </li>
              </ul>
            </template>
          </StoplightItem>
        </template>
        <PageLoading
          v-else
          size="small"
        />
      </template>
    </StoplightContainer>

    <!-- Assessment Notes -->
    <StoplightContainer
      :title="$gettext('Assessments notes')"
      :item-count="moduleCount"
      class-name="assessment-notes"
    >
      <template #body>
        <template v-if="assessmentNotes">
          <StoplightItem
            v-for="module in assessmentNotesIterator()"
            :id="`an-${module.id}`"
            :key="`an-${module.id}`"
            :title="module.label"
            :link-params="module.urlParams"
            :link-title="module.urlParams ? $gettext('See recommendations') : ''"
            :stoplight-color="module.stoplightColor"
            :item-count="moduleCount"
          >
            <template #title>
              <h3>
                {{ module.label }}
                <span
                  v-if="module.notes"
                  class="muted"
                >
                  ({{ module.notes.length }})
                </span>
              </h3>
            </template>
            <template #body>
              <div
                v-if="module.notes"
                class="card-content"
              >
                <div
                  :class="{'notes-container': true, 'clipped': !expandedAssessmentNotes[module.id]}"
                >
                  <template
                    v-for="note in module.notes"
                    :key="note.id"
                  >
                    <AssessmentNote :note="note" />
                  </template>
                </div>
                <a
                  class="interactive-link small"
                  @click="expandedAssessmentNotes[module.id] = !expandedAssessmentNotes[module.id]"
                >
                  {{ expandedAssessmentNotes[module.id] ? $gettext("Show less") : $gettext("Show more") }}
                </a>
              </div>
              <div
                v-else
                class="card-content"
              >
                <em class="muted">
                  {{ $gettext("No assessment notes have been recorded for this child.") }}
                </em>
              </div>
            </template>
          </StoplightItem>
        </template>
        <PageLoading
          v-else
          size="small"
        />
      </template>
    </StoplightContainer>

    <!-- Next Assessments -->
    <StoplightContainer
      :title="$gettext('Next scheduled assessments')"
      :item-count="moduleCount"
      class-name="next-assessments"
    >
      <template #body>
        <template v-if="nextAssessmentDates">
          <StoplightItem
            v-for="card in nextAssessmentDates"
            :key="card.id"
            :class-name="card.id"
            :title="card.label"
            :link-params="card.urlParams"
            :link-title="card.urlParams ? $gettext('Assess now') : ''"
            :is-button="true"
            :item-count="moduleCount"
          >
            <template #body>
              <p>{{ card.text }}</p>
            </template>
          </StoplightItem>
        </template>
        <PageLoading
          v-else
          size="small"
        />
      </template>
    </StoplightContainer>
    <LastSynced :last-synced="lastSynced" />
  </article>
  <article v-else>
    <h1 class="title">
      {{ $gettext("Care Plan") }}
    </h1>
    <PageLoading :with-text="true" />
  </article>
</template>
<style scoped lang="scss">
  .training-mode-label {
    font-size: 0.75rem;
    text-align: center;
    margin: -1rem 0 1rem;
  }

  a h3 {
    color: hsl(0, 0%, 48%);;
  }
  h3 .muted {
    font-weight: normal;
    margin-left: 0.25em;
  }
  .clipped {
    max-height: 3em;
    overflow: clip;
    /* XXX: This mask doesn't play well with the sticky card title. It causes the content of the masked element to float above the sticky header. */
    --mask: linear-gradient(to bottom,
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%,
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    mask: var(--mask);
    /* This width correspond with $tablet */
    @media (min-width: 625px) {
      max-height: 5em;
    }
  }
</style>
