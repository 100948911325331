<!--
This is a single entry showing a stoplight color for a discrete area. It should
be nested within StoplightContainer using the container's `body` slot.

This SFC provides a `body` slot of its own as well as a few required props.
-->
<template>
  <div :class="['stoplight-item', className]">
    <header>
      <component
        :is="linkParams && !isButton ? 'router-link' : 'div'"
        :to="linkParams"
        class="header"
      >
        <slot name="title">
          <div
            v-if="stoplightColor"
            class="icon"
          >
            <span :class="['stoplight', stoplightColor]" />
          </div>
          <h3>
            {{ title }}
          </h3>
        </slot>
        <template v-if="!isWiderThanMinWidth">
          <router-link
            v-if="linkParams && isButton"
            :to="linkParams"
            class="button is-primary is-small"
          >
            {{ linkTitle }}
          </router-link>
          <IconRightArrow v-else-if="linkParams" />
        </template>
      </component>
    </header>
    <div class="content">
      <slot name="body" />
    </div>
    <footer v-if="isWiderThanMinWidth && linkTitle">
      <router-link
        v-if="linkParams"
        :to="linkParams"
        :class="isButton ? 'button is-primary is-small' : 'text-link'"
      >
        {{ linkTitle }}
      </router-link>
    </footer>
  </div>
</template>
<script lang="ts" setup>
  import { computed } from "vue"
  import { RouterLink } from "vue-router"
  import { useWindowSize } from '@vueuse/core'

  import IconRightArrow from '@/components/svg/IconRightArrow.vue'

  interface Props {
    // If title isn't supplied, then you better fill the title slot!
    title?: string,
    linkParams?: { name: string, params: Record<string, string> },
    // link title is shown at the widest viewport width, OR if isButton is true
    linkTitle?: string,
    stoplightColor?: string,
    isButton?: boolean,
    itemCount?: number,
    className?: string,
  }

  const { itemCount } = defineProps<Props>()
  const { width } = useWindowSize()
  let minWidth: number
  switch (itemCount) {
    case 1:
      minWidth = 100
      break
    case 2:
      minWidth = 410
      break
    case 3:
      minWidth = 625
      break
    case 4:
      minWidth = 820
      break
    default:
      minWidth = 1024
      break
  }

  const isWiderThanMinWidth = computed(() => width.value >= minWidth)

</script>
<style lang="scss">
</style>
