<script lang="ts" setup>
import { ref } from "vue"
import { useRoute } from "vue-router"

import {
  setCurrentViewContext,
  getLastUpdateInfo,
  areCachedDataStale,
  showFlashMessage,
  setStalenessNotificationTime,
} from "@/utils/GlobalState"
import { getSiteByCmiId, canEditSite } from "@/services/Site"
import { getCachedChildrenForSite, updateChildrenFromServer } from "@/services/Child"
import useServerRefresher from "@/composables/useServerRefresher"
import { gettext } from "@/utils/Translation"

import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
import PageLoading from "@/components/PageLoading.vue"
import LastSynced from "@/components/LastSynced.vue"
import ClickablePanel from "@/components/ClickablePanel.vue"
import IconCalendar from "@/components/svg/IconCalendar.vue"
import IconSettings from "@/components/svg/IconSettings.vue"
import IconChildren from "@/components/svg/IconChildren.vue"
import IconReport from "@/components/svg/IconReport.vue"
import IconAdd from "@/components/svg/IconAdd.vue"
import IconDescription from "@/components/svg/IconDescription.vue"
import SiteHeader from "@/components/sites/SiteHeader.vue"

const { $gettext } = gettext
const route = useRoute()
const siteCmiId = parseInt(route.params.siteCmiId as string)
const site = ref(null)
const growthDue = ref(null)
const anemiaDue = ref(null)
const feedingScreeningDue = ref(null)
const feedingObservationDue = ref(null)
const earlyidDue = ref(null)
const bestPracticesDue = ref(null)
const canEdit = ref(false)
const pageReady = ref(false)
const lastSynced = ref(undefined)
const { refreshUnderway, serverRefresher } = useServerRefresher()
let staleNotificationQueryKey = ""

async function updateAssessmentsDueNumbers() {
  if (site.value.growthEnabled) {
    const childrenNeedingGrowth = await getCachedChildrenForSite(siteCmiId, { due: "growth" })
    growthDue.value = childrenNeedingGrowth.length
  }
  if (site.value.anemiaEnabled) {
    const childrenNeedingAnemia = await getCachedChildrenForSite(siteCmiId, { due: "anemia" })
    anemiaDue.value = childrenNeedingAnemia.length
  }
  if (site.value.isFeedingScreeningEnabled) {
    const childrenNeedingFeedingScreening = await getCachedChildrenForSite(siteCmiId, { due: "feedingScreening" })
    feedingScreeningDue.value = childrenNeedingFeedingScreening.length
  }
  if (site.value.isFeedingObservationEnabled) {
    const childrenNeedingFeedingObservations = await getCachedChildrenForSite(siteCmiId, { due: "feedingObservation" })
    feedingObservationDue.value = childrenNeedingFeedingObservations.length
  }
  if (site.value.earlyidEnabled) {
    const childrenNeedingEarlyid = await getCachedChildrenForSite(siteCmiId, { due: "earlyid" })
    earlyidDue.value = childrenNeedingEarlyid.length
  }
  if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
    const childrenNeedingBestPractices = await getCachedChildrenForSite(siteCmiId, { due: "bestPractice" })
    bestPracticesDue.value = childrenNeedingBestPractices.length
  }
  lastSynced.value = await getLastUpdateInfo({ type: "siteChildren", localItemId: site.value.id })
}

async function updateFromServer(wasManuallyTriggered = false) {
  await serverRefresher(updateChildrenFromServer, [siteCmiId], staleNotificationQueryKey, wasManuallyTriggered)
    .catch(error => {
      if (error.name === "CONNECTIVITY_REQUIRED") {
        const msg = $gettext("You do not appear to be online.")
        let details = ""
        if (lastSynced.value) {
          details = $gettext("The information shown here was last updated %{ date }.", { date: lastSynced.value })
        }
        else {
          details = $gettext("The information has never been updated from the server.")
        }
        showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
        setStalenessNotificationTime(staleNotificationQueryKey)
      }
      else {
        throw error
      }
    })
  await updateAssessmentsDueNumbers()
}

async function getData() {
  if (siteCmiId) {
    const rawSite = await getSiteByCmiId(siteCmiId)
    if (rawSite) {
      canEdit.value = await canEditSite(rawSite)
      site.value = rawSite
      setCurrentViewContext(rawSite, "site")
      staleNotificationQueryKey = `siteChildren-${rawSite.id}`
      if (await areCachedDataStale({ type: "siteChildren", localItemId: rawSite.id })) {
        updateFromServer()
      }
      else {
        updateAssessmentsDueNumbers()
      }
    }
  }
  pageReady.value = true
}

getData()
</script>

<template>
  <article>
    <SiteHeader :site="site" />
    <PageTitleWithRefresh
      :title="$gettext('Site Home')"
      :refresh-function="updateFromServer"
      :refresh-underway="refreshUnderway"
    />
    <template v-if="pageReady">
      <template v-if="site">
        <h2 class="section-title">
          {{ $gettext("Assessments") }}
        </h2>
        <nav class="block">
          <div class="columns is-mobile is-multiline">
            <ClickablePanel
              v-if="site.growthEnabled"
              panel-class="growth-link"
              :url-params="{ name: 'NeedGrowthAssessmentList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Growth") }}</strong>
              <div
                v-if="growthDue != null"
                :class="{ 'due-count': true, warning: growthDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: growthDue.toString() }) }}
              </div>
            </ClickablePanel>

            <ClickablePanel
              v-if="site.anemiaEnabled"
              panel-class="anemia-link"
              :url-params="{ name: 'NeedAnemiaAssessmentList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Anemia") }}</strong>
              <div
                v-if="anemiaDue != null"
                :class="{ 'due-count': true, warning: anemiaDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: anemiaDue.toString() }) }}
              </div>
            </ClickablePanel>

            <ClickablePanel
              v-if="site.isChildMealtimeBestPracticeAssessmentEnabled"
              panel-class="best-practices-link"
              :url-params="{ name: 'NeedBestPracticesList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Best Practices") }}</strong>
              <div
                v-if="bestPracticesDue != null"
                :class="{ 'due-count': true, warning: bestPracticesDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: bestPracticesDue.toString() }) }}
              </div>
            </ClickablePanel>
            <ClickablePanel
              v-if="site.isFeedingScreeningEnabled"
              :url-params="{ name: 'NeedFeedingScreeningList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Feeding Screening") }}</strong>
              <div
                v-if="feedingScreeningDue != null"
                :class="{ 'due-count': true, warning: feedingScreeningDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: feedingScreeningDue.toString() }) }}
              </div>
            </ClickablePanel>
            <ClickablePanel
              v-if="site.isFeedingObservationEnabled"
              :url-params="{ name: 'NeedFeedingObservationList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Feeding Observation") }}</strong>
              <div
                v-if="feedingObservationDue != null"
                :class="{ 'due-count': true, warning: feedingObservationDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: feedingObservationDue.toString() }) }}
              </div>
            </ClickablePanel>
            <ClickablePanel
              v-if="site.earlyidEnabled"
              panel-class="earlyid-link"
              :url-params="{ name: 'NeedDevelopmentalScreeningList', params: { siteCmiId } }"
              outer-column-class="module-panel is-6"
            >
              <strong>{{ $gettext("Developmental Screening") }}</strong>
              <div
                v-if="earlyidDue != null"
                :class="{ 'due-count': true, warning: earlyidDue > 0 }"
              >
                {{ $gettext("%{ number } due", { number: earlyidDue.toString() }) }}
              </div>
            </ClickablePanel>
          </div>
          <div class="columns site-panels">
            <ClickablePanel
              :url-params="{ name: 'SiteAssessmentCalendar', params: { siteCmiId } }"
            >
              <template #leftIcon>
                <div class="left-icon">
                  <IconCalendar />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Calendar") }}</strong>
              </template>
            </ClickablePanel>
          </div>
        </nav>

        <h2 class="section-title">
          {{ $gettext("Children") }}
        </h2>
        <nav class="block site-panels">
          <div class="columns">
            <ClickablePanel
              :url-params="{ name: 'ChildList', params: { siteCmiId } }"
            >
              <template #leftIcon>
                <div class="left-icon">
                  <IconChildren />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Look Up a Child") }}</strong>
              </template>
            </ClickablePanel>
            <ClickablePanel
              :url-params="{ name: 'ChildCreate', params: { siteCmiId } }"
            >
              <template #leftIcon>
                <div class="left-icon">
                  <IconAdd />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Add a Child") }}</strong>
              </template>
            </ClickablePanel>
          </div>
        </nav>

        <h2 class="section-title">
          {{ $gettext("Reports") }}
        </h2>
        <nav class="block site-panels">
          <div class="columns site-panels">
            <ClickablePanel :url-params="{ name: 'ClinicalReportReferrals', params: { siteCmiId } }">
              <template #leftIcon>
                <div class="left-icon">
                  <IconReport />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Clinical reports") }}</strong>
              </template>
            </ClickablePanel>
            <ClickablePanel :url-params="{ name: 'SiteLevelDemographicReport', params: { siteCmiId } }">
              <template #leftIcon>
                <div class="left-icon">
                  <IconReport />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Summary reports") }}</strong>
              </template>
            </ClickablePanel>
          </div>
        </nav>

        <h2 class="section-title">
          {{ $gettext("My Site") }}
        </h2>
        <nav class="block site-panels">
          <div class="columns">
            <ClickablePanel
              v-if="canEdit"
              :url-params="{ name: 'SiteManage' }"
            >
              <template #leftIcon>
                <div class="left-icon">
                  <IconSettings />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Manage Site") }}</strong>
              </template>
            </ClickablePanel>
            <ClickablePanel
              v-if="site.canCreateSiteVisitReports"
              :url-params="{ name: 'SiteVisitReportListForSite' }"
            >
              <template #leftIcon>
                <div class="left-icon">
                  <IconDescription />
                </div>
              </template>
              <template #default>
                <strong>{{ $gettext("Site Visits") }}</strong>
              </template>
            </ClickablePanel>
          </div>
        </nav>
        <LastSynced :last-synced="lastSynced" />
      </template>
      <div
        v-else
        class="block"
      >
        <p>
          {{ $gettext("No site is currently selected. Please go to 'My Sites' to choose one.") }}
        </p>
        <router-link
          :to="{ name: 'SiteList' }"
          class="button"
        >
          {{ $gettext("My Sites") }}
        </router-link>
      </div>
    </template>
    <PageLoading v-else />
  </article>
</template>

<style lang="scss" scoped>
  .module-panel {
    flex: 1;
  }
  .left-icon {
    max-width: 2em;
    margin-right: 1em;
    @media screen and (min-width: 625px) {
      max-width: 4em;
    }
  }
</style>
