<script setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { getChildById } from "@/services/Child"
import { getSiteByCmiId } from "@/services/Site"
import { setCurrentViewContext, showFlashMessage } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { shouldChildDoFeedingScreeningInstead, setChildToDoFeedingScreeningInstead } from "@/services/Child"

import ChildHeader from "@/components/children/ChildHeader.vue"
import PageLoading from "@/components/PageLoading.vue"

const { $gettext } = gettext
const route = useRoute()
const router = useRouter()
const childId = parseInt(route.params.childId)
const siteCmiId = parseInt(route.params.siteCmiId)
const assessmentType = route.params.assessmentType

const child = ref(null)
const pageReady = ref(false)
const failureMessage = ref(null)

async function getData() {
  const site = await getSiteByCmiId(siteCmiId)
  if (!site) {
    failureMessage.value = "Invalid site"
    pageReady.value = true
    return
  }
  if (!["a", "d", "g", "f", "o", "b"].includes(assessmentType)) {
    failureMessage.value = "Invalid assessment type"
    pageReady.value = true
    return
  }
  else {
    const ineligibleType = (
      (assessmentType === "g" && !site.growthEnabled) ||
      (assessmentType === "d" && !site.earlyidEnabled) ||
      (assessmentType === "a" && !site.anemiaEnabled) ||
      (assessmentType === "f" && !site.isFeedingScreeningEnabled) ||
      (assessmentType === "o" && !site.isFeedingObservationEnabled) ||
      (assessmentType === "b" && !site.isChildMealtimeBestPracticeAssessmentEnabled)
    )
    if (ineligibleType) {
      failureMessage.value = "Invalid assessment type"
      pageReady.value = true
      return
    }
  }
  child.value = await getChildById(childId)
  if (!child.value) {
    failureMessage.value = "Cannot find child"
    pageReady.value = true
    return
  }
  setCurrentViewContext(child.value, "child")
  pageReady.value = true
}

async function createAssessment() {
  switch (assessmentType) {
    case "g":
      router.push({ name: "GrowthDateOfAssessment", params: { childId } })
      return
    case "a":
      router.push({ name: "AnemiaPreface", params: { childId } })
      return
    case "d":
      router.push({ name: "DevelopmentalScreeningDateOfAssessment", params: { childId } })
      return
    case "f":
      router.push({ name: "FeedingScreeningDateOfAssessment", params: { childId } })
      return
    case "o":
      if (await shouldChildDoFeedingScreeningInstead(child.value)) {
        await setChildToDoFeedingScreeningInstead(child.value)
        showFlashMessage({ msg: $gettext("Because this child has not been screened for feeding recently, a feeding screening is required to determine if an observation is necessary.") })
        router.push({ name: "FeedingScreeningDateOfAssessment", params: { childId } })
      }
      else {
        router.push({ name: "FeedingObservationDateOfAssessment", params: { childId } })
      }
      return
    case "b":
      router.push({ name: "PracticesDateOfAssessment", params: { childId } })
      return
  }
}

getData()
</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Child Confirmation") }}
    </h1>

    <template v-if="pageReady && !failureMessage">
      <p class="block">
        <strong>
          {{ $gettext("Please confirm the identity of this child:") }}
        </strong>
      </p>

      <dl class="block content">
        <dt>{{ $gettext("Name") }}</dt>
        <dd>{{ child.fullName }}</dd>
        <dt>{{ $gettext("Sex") }}</dt>
        <dd>{{ child.sexDisplay }}</dd>
        <dt v-if="child.dob">
          {{ $gettext("Current Age") }}
        </dt>
        <dd v-if="child.dob">
          {{ child.getNiceAge(new Date()) }}
        </dd>
        <dt>{{ $gettext("Date of Birth") }}</dt>
        <dd>{{ child.dob?.toLocaleDateString() || $gettext("Unknown") }}</dd>
        <dt>{{ $gettext("Diagnoses") }}</dt>
        <dd>
          <template v-if="child.diagnoses?.length">
            <ul>
              <li
                v-for="dx in child.diagnoses"
                :key="dx"
              >
                {{ dx }}
              </li>
            </ul>
          </template>
          <template v-else>
            {{ $gettext("None") }}
          </template>
        </dd>
        <dt>{{ $gettext("Count Me In ID") }}</dt>
        <dd>{{ child.cmiId || $gettext("ID not yet generated") }}</dd>
      </dl>

      <p class="block">
        <a
          class="button is-primary"
          @click="createAssessment"
        >
          {{ $gettext("Continue") }}
        </a>
      </p>
    </template>
    <div
      v-else-if="failureMessage"
      class="notification is-danger"
    >
      {{ failureMessage }}
    </div>
    <PageLoading v-else />
  </article>
</template>

<style>
  dt {
    font-weight: bold;
  }
  dd,
  dd li {
    text-transform: capitalize;
  }
</style>
