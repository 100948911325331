<!--
This is a container for multiple StoplightItems, which should
be nested within this element's `body` slot.
-->
<template>
  <section :class="['stoplight-container', className, widthClass]">
    <div class="card-header">
      <h2>{{ title }}</h2>
    </div>
    <div class="stoplight-items">
      <slot name="body" />
    </div>
  </section>
</template>
<script lang="ts" setup>
  import { ref } from "vue"

  interface Props {
    title: string,
    className?: string,
    itemCount?: number,
  }
  const { itemCount } = defineProps<Props>()
  const widthClass = ref("item-count-five")

  switch (itemCount) {
    case 1:
      widthClass.value = "item-count-one"
      break
    case 2:
      widthClass.value = "item-count-two"
      break
    case 3:
      widthClass.value = "item-count-three"
      break
    case 4:
      widthClass.value = "item-count-four"
      break
    default:
      widthClass.value = "item-count-five"
  }
</script>
<style lang="scss" scoped>
</style>
